@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.p-topFurusato {
  padding: 48px;

  @include screen.screen("smartphone") {
    padding: 24px 16px;
  }

  .p-topFurusato__title {
    margin-bottom: 16px;
    text-align: center;

    @include typography.title-2;
  }

  .p-topFurusato__description {
    @include typography.body;
    font-size: variable.$font-size4;
    line-height: 1.6;
  }

  .p-topFurusatoItems {
    display: grid;
    align-items: center;
    gap: 20px;
    margin-bottom: 28px;

    @include screen.screen("tablet-and-pc") {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
  }

  .p-topFurusatoItem {
    > img {
      width: 100%;
      height: auto;
    }
  }
}
