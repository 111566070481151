@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";

/* stylelint-disable tabechoku/variable-disallowed-list -- とりあえずファイルを移動、後で対応します */
.communication {
  .communication__inner {
    @include screen.screen("tablet-and-pc") {
      padding: 24px 0 32px;
    }
  }

  .communicationItems {
    @include screen.screen("tablet-and-pc") {
      margin-bottom: 24px;
    }
  }

  .swiper-slide {
    opacity: 0.2;
    transition: opacity 0.3s 0.1s;
  }

  .swiper-slide-prev,
  .swiper-slide-next,
  .swiper-slide-active {
    opacity: 1;
  }
}
/* stylelint-enable tabechoku/variable-disallowed-list */
