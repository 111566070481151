@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/variables/font";

.c-topAnnotationDialog {
  max-width: 308px;
  border: none;

  border-radius: 12px;
  background-color: color.$color-gray0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

  padding: 28px 20px 16px;

  &::backdrop {
    background-color: rgba(color.$color-gray3, 0.6);
  }

  .c-topAnnotationDialog__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .c-topAnnotationDialog__title {
    display: flex;
    gap: 2px;
  }

  .c-topAnnotationDialog__tilteBody {
    color: color.$color-annotation;
    @include typography.title-2;
  }

  .c-topAnnotationDialog__body {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .c-topAnnotationDialog__producerMessages {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @include typography.body;
    color: color.$color-gray1;
  }

  .c-topAnnotationDialog__deliveryDateTime {
    width: 100%;

    font-size: font.$font-size9;
    font-weight: font.$font-weight-normal;
    line-height: 1;
    text-align: right;
  }

  .c-topAnnotationDialog__actionList {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .c-topAnnotationDialog__actionItem {
    width: 100%;
    text-align: center;
  }

  .c-topAnnotationDialog__closeButton {
    @include typography.body-small;

    background-color: color.$color-gray0;

    &:focus {
      border: 1px solid color.$color-primary1;
    }
  }
}
