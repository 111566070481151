@use "../Foundation/variable";
@use "../Foundation/mixin/screen";
@use "../Foundation/mixin/arrow";
@use "../Foundation/mixin/typography";
@use "../Foundation/mixin/line-clamp";
@use "../Object/Component/top/c-topHeaderAttention";
@use "../Object/Component/top/c-topAnnotationDialog";
@use "../Object/Component/top/c-topFloatingAction";
@use "../Object/Component/card/c-postCard";

@import "./top/noticeBanner";
@import "./top/communication";
@import "./top/p-regularService";
@import "./top/p-topFurusato";

/* stylelint-disable selector-class-pattern */
/* TODO: トップページ全般でスタイルガイドに従ったクラスに変更できたらstylelint-disableを外す */

.index,
.topBanner {
  .topBanner__navWrapper,
  .recipe__navWrapper,
  .communication__navWrapper {
    position: relative;
  }

  .swiper-wrapper {
    @include screen.screen("tablet-and-pc") {
      margin-bottom: 24px;
    }
  }

  .swiper-pagination {
    padding: 12px 0 0;
    position: relative;

    @include screen.screen("tablet-and-pc") {
      padding: 0 0 8px;
      margin-top: -10px;
    }
  }

  .swiper-button-prev {
    left: -12px;
  }

  .swiper-button-next {
    right: -12px;
  }
}

.kv-wrap {
  width: 100%;
  position: relative;
  max-width: 1240px;
  margin: 0 auto;

  @include screen.screen("pc-only") {
    padding: 0 20px;
  }

  .aboutLinkWrap {
    position: absolute;
    bottom: 0px;
    right: 20px;
    padding: 8px 8px 8px 12px;
    background: variable.$color-primary1;
    border-radius: 9px 0px 0px 0px;
    @include screen.screen("smartphone") {
      padding: 4px 4px 4px 8px;
    }

    > .aboutLink {
      padding-right: 20px;
      color: variable.$color-gray0;
      @include typography.button-small;

      @include screen.screen("smartphone") {
        @include typography.caption-bold;
      }
    }

    &::before {
      position: absolute;
      right: 10px;
      top: 14px;
      @include arrow.arrow(variable.$color-gray0, 2px, arrow.$arrowDirectionRight);

      @include screen.screen("smartphone") {
        top: 11px;
      }
    }

    @include screen.screen("smartphone") {
      right: 0;
    }
  }

  .kv-img {
    width: 100%;
    height: auto;
  }
}

.kv-recommend-env {
  display: none;
  margin: 20px 0;
  padding: 20px 20px;
  border: 1px solid variable.$color-gray5;
  font-size: 16px;
  text-align: center;

  .link {
    color: #636363;
    text-decoration: underline;
  }
}

*::-ms-backdrop,
.kv-recommend-env {
  /* IE11に適応 */
  display: block;
}

.topBanner {
  max-width: 1240px;
  margin: 16px auto -20px;
  padding: 0 20px;

  @include screen.screen("smartphone") {
    padding: 0;
    margin: 16px auto 0;
  }

  .topBanner__image {
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  .swiper-button-prev {
    left: -14px;
    top: calc(50% - 24px);
    width: 40px;
    height: 40px;
  }

  .swiper-button-next {
    right: -12px;
    top: calc(50% - 24px);
    width: 40px;
    height: 40px;
  }

  /* ページネーションのサイズと色 */
  .swiper-pagination-bullet {
    background-color: variable.$color-primary2;
    height: 6px;
    width: 6px;
  }
}

.p-topSection {
  margin-bottom: 16px;
  @include screen.screen("smartphone") {
    background: variable.$color-gray0;
    margin-bottom: 8px;
  }

  &.p-topSection--background {
    @include screen.screen("smartphone") {
      background: variable.$color-gray0;
    }
    @include screen.screen("tablet-and-pc") {
      background: variable.$color-gray6;
      border-radius: 8px;
    }
  }

  &:first-of-type {
    margin-top: 8px;
  }

  .p-topSection__inner {
    padding: 28px 0;

    @include screen.screen("pc-only") {
      padding: 12px 0;
    }
  }

  .p-topSection__spOnlyInner {
    padding: 14px 0 0 0;
  }
}

.bnr-long-wrap {
  margin-bottom: 40px;
  transition: 300ms;

  li {
    margin-bottom: 12px;

    a {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .first-banner-pc {
    margin-top: 25px;
  }

  .for-anv-banner {
    margin-top: 37px;
  }
}

/* bnr-long-wrap */

// FIXME: top-head-alert--loyal が依存しているので、top-head-alert--loyal を書き直したら消せる
.top-head-alert {
  display: flex;
  width: 100%;
  padding: 10px;
  background: variable.$color-order2;
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 0;
  @include screen.screen("tablet-and-pc") {
    margin-bottom: 15px;
  }

  &:hover {
    opacity: 0.9;
  }

  .top-head-alert-inner {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 25px;
    margin: 0 auto;

    span {
      text-align: center;
      background: #fff;
      border-radius: 4px;
      min-width: 75px;
      padding: 5px;
      color: variable.$color-order2;
      margin-right: 8px;
      display: inline-block;
      @include screen.screen("tablet-and-pc") {
        margin-right: 16px;
        min-width: 100px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 6px);
      right: 5px;
      @include arrow.arrow(variable.$color-gray0, 2px, arrow.$arrowDirectionRight);
    }
  }
}

.top-head-alert-loyal-outer {
  margin: 0;
  padding: 0;
}

.top-head-alert--loyal {
  background: variable.$color-progress;

  &:hover {
    opacity: 1;
  }

  .top-head-alert-inner--loyal {
    color: variable.$color-gray1;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
  }

  .top-head-alert-inner-close-btn {
    display: flex;
    margin: auto;

    > img {
      width: 16px;
      height: 16px;
      margin: auto 12px;
    }
  }
}

.app-banner {
  padding: 10px;
  background: #f2f2f7;
  display: flex;
  align-items: center;
  line-height: 1.3;
  letter-spacing: 0;

  @include screen.screen("tablet-and-pc") {
    display: none;
  }

  .app-close {
    position: relative;
    min-width: 12px;
    min-height: 12px;
    margin-right: 8px;
    cursor: pointer;

    &::after,
    &::before {
      background: #bbb;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 6px;
      width: 12px;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .app-icon {
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 50px;
    margin-right: 8px;
    width: 50px;
  }

  .app-banner-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .app-name {
      font-size: 12px;
      margin-bottom: 2px;
    }

    .app-description {
      color: #999;
      font-size: 10px;
    }

    a {
      color: #007aff;
      margin-left: 8px;
      min-width: 30px;
    }
  }
}

.p-top-annotation {
  max-width: 1200px;
  margin: auto;
  padding: 30px 15px;
  background-color: white;
  @include screen.screen("tablet-and-pc") {
    padding: 30px 0;
  }

  &_list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: #9b9b96;
    font-size: 10px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "\203B\0020"; // "※ "
    }

    > li {
      width: calc(100% - 1.5em);
      margin-bottom: 0.5em;
      padding-left: 1.5em;
      line-height: 1.5;
    }
  }
}

.recipe-wrapper {
  @include screen.screen("tablet-and-pc") {
    padding: 32px 0;
  }

  .swiper-slide {
    opacity: 0.2;
    transition: opacity 0.3s 0.1s;
  }

  .swiper-slide-prev,
  .swiper-slide-next,
  .swiper-slide-active {
    opacity: 1;
  }
}

// TODO キユーピーのキャンペーンが終わったら消せる。!important を使っているのは、元々が disipaly: block
.u-displayNone {
  display: none !important;
}

.u-displayBlock {
  display: block !important;
}

.p-topReelWrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  @include screen.screen("smartphone") {
    display: flex;
    gap: 8px;
    overflow: scroll;
  }

  .c-reelCard {
    @include screen.screen("smartphone") {
      width: 40%;
      min-width: 140px;
    }
  }
}

/* stylelint-enable selector-class-pattern */
