@use "../../../Foundation/variables/color";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/arrow";
@use "../../../Foundation/mixin/screen";

.c-topHeaderAttention {
  max-width: 1200px; // FIXME： dimension.scss に記載されていないサイト内コンテンツの幅。ルールを決めて実装したら消せる
  margin: 0 auto;
  display: block;
  background-color: color.$color-primary4;

  // FIXME: .main-content の background: #f5f1eb; とあり、.p-topSection の一番最初に margin-top で背景色を表示しているため マイナスマージンで隠している。背景色の管理を直したら削除できる
  &:last-of-type {
    margin-bottom: -8px;
    @include screen.screen("tablet-and-pc") {
      margin-bottom: 0;
    }
  }

  & + .c-topHeaderAttention {
    margin-top: 1px;
  }

  .c-topHeaderAttention__container {
    @include typography.subTitle-small;
    color: color.$color-gray1;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    padding: 8px 16px;

    &::after {
      content: "";
      height: 8px;
      min-width: 8px;
      max-width: 8px;
      transform: rotate(45deg);
      border-top: 2px solid color.$color-gray1;
      border-right: 2px solid color.$color-gray1;
    }
  }

  .c-topHeaderAttention__label {
    display: flex;
    align-items: flex-start;

    color: color.$color-gray0;
    white-space: nowrap;

    border-radius: 4px;
    background: color.$color-annotation;

    padding: 4px 8px;
  }
}
