@use "../../Foundation/mixin/screen";

.noticeBanner {
  display: flex;
  gap: 12px;
  margin: 8px 0;
  padding: 24px 44px;

  @include screen.screen("smartphone") {
    padding: 24px 22px;
  }
  .noticeBanner__link {
    flex: 1;

    > img {
      width: 100%;
      height: auto;
    }
  }
}
