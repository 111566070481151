@use "../../../Foundation/mixin/screen";

.c-topFloatingAction {
  width: 100%;

  position: fixed;
  bottom: 0;

  display: flex;
  flex-direction: row-reverse;

  padding: 0 8px 16px 0px;

  @include screen.screen("tablet-and-pc") {
    padding: 0 32px 32px 0px;
  }
}
