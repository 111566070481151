@use "../../../Foundation/mixin/screen";
@use "../../../Foundation/mixin/typography";
@use "../../../Foundation/mixin/line-clamp";
@use "../../../Foundation/variables/color";
@use "../../../Foundation/variables/font";

.c-postCard {
  flex-grow: 1;

  .c-postCard__inner {
    background-color: color.$color-gray0;
    border: 1px solid color.$color-gray5;
    border-radius: 4px;
    overflow: hidden;
  }

  .c-postCard__thumnail {
    width: 100%;
    height: auto;
  }

  .c-postCard__link:hover {
    opacity: 0.9;
  }

  .c-postCard__content {
    display: grid;
    grid-template-columns: 45px 1fr;
    column-gap: 4px;

    font-size: font.$font-size7;
    letter-spacing: 0;
    padding: 16px 16px 20px;
  }

  .c-postCard__contentImage {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .c-postCard__contentUser {
    height: 20px;
    @include line-clamp.line-clamp(1);
  }

  .c-postCard__contentUserName {
    position: relative;
    display: inline;

    @include typography.subTitle-small;

    &:not(:first-child)::before {
      content: "";
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
      border-top: 1px solid color.$color-primary2;
      border-right: 1px solid color.$color-primary2;
      position: absolute;
      top: 3px;
      left: 0;
    }

    &:not(:first-child) {
      padding-left: 20px;
    }
  }

  .c-postCard__contentPostDate {
    color: color.$color-gray2;
    @include typography.caption;
  }

  .c-postCard__contentBody {
    height: 64px;

    grid-column: 1 / 3;
    margin-top: 12px;

    @include line-clamp.line-clamp(3);
    @include typography.body-small;
  }
}
