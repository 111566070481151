@use "../../Foundation/variable";
@use "../../Foundation/mixin/screen";
@use "../../Foundation/mixin/typography";

.p-regularService {
  @include screen.screen("tablet-and-pc") {
    padding: 32px 48px;
  }

  .p-regularService__description {
    @include typography.body;
    text-align: center;

    @include screen.screen("smartphone") {
      text-align: left;
    }
  }

  .p-regularServiceItems {
    display: flex;
    margin: 20px 0;
    gap: 8px;

    @include screen.screen("smartphone") {
      display: block;
      margin: 16px 0;
    }
  }

  .p-regularServiceItem {
    display: flex;
    flex-direction: column;
    background: variable.$color-gray0;

    @include screen.screen("tablet-and-pc") {
      flex: 1;
      border-radius: 4px;
      overflow: hidden;
    }

    @include screen.screen("smartphone") {
      &:nth-child(n + 2) {
        margin-top: 8px;
      }
    }

    > img {
      width: 100%;
      height: auto;

      @include screen.screen("smartphone") {
        aspect-ratio: variable.$aspect-banner;
        object-fit: cover;
      }
    }
  }

  .p-regularService__annotation {
    @include typography.caption;
    text-align: right;
    margin-bottom: 20px;
  }
}
